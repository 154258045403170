import Barba from 'barba.js'
import TextSplitter from './textSplitter.js';

class BarbaHelper
{
    /**
     * Constructor
     */
    constructor( options )
    {

    }

    getLink(className) {
        return document.querySelectorAll(className)
    }

    settings() {
        Barba.Dispatcher.on('newPageReady', function(currentStatus, oldStatus, barbaContainer, newPageRawHTML) {

            if ( Barba.HistoryManager.history.length === 1 ) {  // Première vue
               return; // Aucune mise à jour n'est nécessaire pour le moment
            }

            const link = currentStatus.url

            const navigationLinks = document.querySelectorAll('.menu-item');
            const navigationLinkIsActive = document.querySelector(`.menu-item a[href="${link}"]`);
            let parent = null
            Array.prototype.forEach.call(navigationLinks, (navigationLink) => navigationLink.classList.remove('active')); // remove CSS class 'is-active' from all .navigation__links

            if(document.querySelector(`.menu-item a[href="${link}"]`)) {
              parent = navigationLinkIsActive.parentNode
              parent.classList.add('active');
            }

            // J'ai emprunté à jquery-pjax
            var $newPageHead = jQuery( '<head />' ).html(
                jQuery.parseHTML(
                  newPageRawHTML.match( /<head[^>]*>([\s\S.]*)<\/head>/i )[ 0 ],
                  document,
                  true
               )
            );
            // Tag que vous souhaitez modifier (Veuillez modifier en fonction de votre environnement)
            var headTags = [
               "link[rel='canonical']",
               "link[rel='shortlink']",
               "link[rel='alternate']",
               "meta[name='description']",
               "meta[property^='og']",
               "meta[name^='twitter']",
               "meta[name='robots']"
            ].join( ',' );
            jQuery('head').find(headTags).remove(); // Supprimer le tag
            $newPageHead.find(headTags).appendTo('head'); // Ajouter un tag
         });
    }

    fadeTransition() {
        var FadeTransition = Barba.BaseTransition.extend({
            start: function() {
              Promise
                .all([this.newContainerLoading, this.fadeOut()])
                .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
              return jQuery(this.oldContainer).animate({ opacity: 0 }).promise();
            },

            fadeIn: function() {

              window.scroll(0,0)
              
              var _this = this;
              var $el = jQuery(this.newContainer);

              let page = {
                page: this.newContainer.querySelector('main'),
                title: this.newContainer.querySelector('.philosophy__intro__container__title p')
              }

              let header = {
                menu: document.querySelector('.header'),
                location: document.querySelector('.header__location'),
                section: document.querySelector('.header__sectionIndicator'),
                social: document.querySelector('.header__socials'),
                contact: document.querySelector('.header__contactBtn'),
                container: document.querySelector('.header__container')
              }
              let cursor = document.querySelector('.cursor')
              
              cursor.classList.add('hide')

                page.page.classList.add('activePage')
                header.menu.classList.remove('hideAnimation')
                header.location.classList.remove('hideAnimation')
                header.section.classList.remove('hideAnimation')
                header.social.classList.remove('hideAnimation')
                header.contact.classList.remove('hideAnimation')
                header.container.classList.remove('hide')

              jQuery(this.oldContainer).hide();

              $el.css({
                visibility : 'visible',
                opacity : 0
              });

              $el.animate({ opacity: 1 }, 400, function() {

                _this.done();
                window.flinked.reinit()
              });
            }
          });

          return FadeTransition
    }

    mobileTransition() {
        var mobileTransition = Barba.BaseTransition.extend({
            start: function() {
              Promise
                .all([this.newContainerLoading, this.fadeOut()])
                .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
              let deferred = Barba.Utils.deferred()
              let body = document.querySelector('body');

              if(body.classList.contains('openMobileMenu')) {
                body.classList.remove('openMobileMenu')
              }

              this.oldContainer.style.transition = "opacity ease 0.5s"
              this.oldContainer.style.opacity = "0"

              setTimeout(() => {
                window.scroll(0,0)
              }, 600);

              setTimeout(() => {
                // deferred.resolve();
              }, 650);
            },

            fadeIn: function() {

              var _this = this;
              var $el = jQuery(this.newContainer);

              let page = {
                page: this.newContainer.querySelector('main'),
                title: this.newContainer.querySelector('.philosophy__intro__container__title p')
              }

              let containerMenu = document.querySelector('.header__container');
              containerMenu.classList.remove('hide')

              page.page.classList.add('activePage')

              jQuery(this.oldContainer).hide();

              $el.css({
                visibility : 'visible',
                opacity : 0
              });

              $el.animate({ opacity: 1 }, 900, function() {

                _this.done();
                window.flinked.reinit()
              });
            }
          });

          return mobileTransition
    }

    BasicTransition() {
        var BasicTransition = Barba.BaseTransition.extend({
            start: function() {
              Promise
                .all([this.newContainerLoading, this.fadeOut()])
                .then(this.fadeIn.bind(this));
            },

            fadeOut: function() {
              let deferred = Barba.Utils.deferred()

              let transitionWrapper = document.querySelector('.transition__wrapper')
              let cursor = document.querySelector('.cursor')
              let body = document.querySelector('body');
              let header = {
                menu: document.querySelector('.header'),
                location: document.querySelector('.header__location'),
                section: document.querySelector('.header__sectionIndicator'),
                social: document.querySelector('.header__socials'),
                contact: document.querySelector('.header__contactBtn'),
              }

              // header.menu.classList.add('hideAnimation')
              header.location.classList.add('hideAnimation')
              header.section.classList.add('hideAnimation')
              header.social.classList.add('hideAnimation')
              header.contact.classList.add('hideAnimation')

              this.oldContainer.classList.add('hideContainer')

              cursor.classList.add('hide')

              if(!body.classList.contains('openMobileMenu')) {
                header.menu.classList.add('hideAnimation')
                setTimeout(() => {
                  transitionWrapper.classList.add('active')
                }, 250);
  
                setTimeout(() => {
                  window.scroll(0,0)
                }, 250 + 800);
  
                setTimeout(() => {
                  deferred.resolve();
                }, 250 + 1000);
              } else {
                window.scroll(0,0)
                deferred.resolve();
              }

              return deferred.promise;
            },

            fadeIn: function() {

              var _this = this;
              var $el = jQuery(this.newContainer);

              let transitionWrapper = document.querySelector('.transition__wrapper')
              let page = {
                page: this.newContainer.querySelector('main'),
                title: this.newContainer.querySelector('.philosophy__intro__container__title p')
              }
              let body = document.querySelector('body');
              let header = {
                menu: document.querySelector('.header'),
                location: document.querySelector('.header__location'),
                section: document.querySelector('.header__sectionIndicator'),
                social: document.querySelector('.header__socials'),
                contact: document.querySelector('.header__contactBtn'),
                container: document.querySelector('.header__container')
              }

              if(!body.classList.contains('openMobileMenu')) {
                transitionWrapper.classList.add('remove')
              } else {
                body.classList.add('mobileMenuTrans')
                setTimeout(() => {
                  body.classList.add('noTrans')
                  setTimeout(() => {
                    body.classList.remove('mobileMenuTrans')
                    body.classList.remove('openMobileMenu')
                  }, 15);
                  setTimeout(() => {
                    body.classList.remove('noTrans')
                  }, 30);
                }, 750);
              }


              setTimeout(() => {
                page.page.classList.add('activePage')
              }, 500);

              setTimeout(() => {
                header.menu.classList.remove('hideAnimation')
                header.container.classList.remove('hide')
                header.location.classList.remove('hideAnimation')
                header.section.classList.remove('hideAnimation')
                header.social.classList.remove('hideAnimation')
                header.contact.classList.remove('hideAnimation')
              }, 800);

              setTimeout(() => {
                transitionWrapper.classList.add('noTrans')
                setTimeout(() => {
                  transitionWrapper.classList.remove('remove')
                  transitionWrapper.classList.remove('active')
                }, 10);
                setTimeout(() => {
                  transitionWrapper.classList.remove('noTrans')
                  _this.done();
                  window.flinked.reinit()
                }, 50);
              }, 700);


              jQuery(this.oldContainer).hide();

              $el.css({
                visibility : 'visible',
              });
            }
          });

          return BasicTransition
    }

    caseTransition(link) {
      var context = this;

      var caseTransition = Barba.BaseTransition.extend({
          start: function() {
            Promise
              .all([this.newContainerLoading, this.fadeOut()])
              .then(this.fadeIn.bind(this));
          },

          fadeOut: async function() {
            let deferred = Barba.Utils.deferred()

            let parentEl = link;
            let wrapper = link.parentNode

            let header = {
              menu: document.querySelector('.header'),
              location: document.querySelector('.header__location'),
              section: document.querySelector('.header__sectionIndicator'),
              social: document.querySelector('.header__socials'),
              contact: document.querySelector('.header__contactBtn'),
              transition: document.querySelector('.transition__case')
            }

            const bgW = document.querySelector('.bgIconContainer')

            let section = {
              wrapper: wrapper,
              container: parentEl,
              bg: parentEl.querySelector('.home__work__container__project__item__background'),
              item: parentEl.querySelector('.home__works__container__projects__item__img'),
              offset: null
            }

            let cursor = document.querySelector('.cursor')
              
            section.offset = context.getCoords(section.container).top

            console.log(context.getCoords(section.container))

            if(context.getCoords(section.container).left >= section.container.offsetWidth) {
              window.flinked.right = true
            }

            header.menu.classList.add('hideAnimation')
            header.location.classList.add('hideAnimation')
            header.section.classList.add('hideAnimation')
            header.social.classList.add('hideAnimation')
            header.contact.classList.add('hideAnimation')

            bgW.style.opacity = "0"

            cursor.classList.add('hide')

            if(window.screenY === section.offset) {
              section.wrapper.classList.add('activeCaseAnimation')
              section.container.classList.add('activeCaseAnimation')
              this.oldContainer.classList.add('activeCaseAnimation__page')
            } else {
              section.container.style.position="sticky"
              section.container.style.top="0%"
              setTimeout(() => {
                context.autoScroll(800, section.offset + ((window.innerHeight - section.container.offsetHeight) / 2))
              }, 60);

              setTimeout(() => {
                section.container.classList.add('caseBeforeScroll')
              }, 850);

              setTimeout(() => {
                section.wrapper.classList.add('activeCaseAnimation')
                section.container.classList.add('activeCaseAnimation')
                section.container.classList.add('caseAnimationScroll')
                section.wrapper.classList.add('caseAnimationScroll')
                this.oldContainer.classList.add('activeCaseAnimation__page')
                
              }, 850 + 500);
            }

            setTimeout(() => {
              deferred.resolve();
            }, 2500);

            return deferred.promise;
          },

          fadeIn: function() {

            var _this = this;
            var $el = jQuery(this.newContainer);

            window.scroll(0,0)

            let transitionWrapper = document.querySelector('.transition__wrapper')
            let page = {
              page: this.newContainer.querySelector('main'),
              title: this.newContainer.querySelector('.philosophy__intro__container__title p'),
              image: this.newContainer.querySelector('.singleWork__main__container__img'),
              imageContainer: this.newContainer.querySelector('.singleWork__main__container__imgWrapper')
            }

            let header = {
              menu: document.querySelector('.header'),
              container: document.querySelector('.header__container'),
              location: document.querySelector('.header__location'),
              section: document.querySelector('.header__sectionIndicator'),
              social: document.querySelector('.header__socials'),
              contact: document.querySelector('.header__contactBtn'),
            }


            if(window.flinked.right) {
              page.imageContainer.style.transform = `translateX(100%)`
              // page.imageContainer.style.marginLeft = `${window.innerWidth - window.offsetWidth}px`
              // page.imageContainer.style.transition = `0s`
            }


            setTimeout(() => {
              if(window.flinked.right) {
                // page.imageContainer.style.transition = `all ease 0.8s 0.8s, transform ease 1.3s`
                page.imageContainer.style.transition = `all ease 1.3s 0.6s, transform ease 1.3s`
              }
              page.page.classList.add('activePage')
            }, 50);

            setTimeout(() => {
              header.menu.classList.remove('hideAnimation')
              header.location.classList.remove('hideAnimation')
              header.section.classList.remove('hideAnimation')
              header.social.classList.remove('hideAnimation')
              header.contact.classList.remove('hideAnimation')
              header.container.classList.remove('hide')
            }, 200);


            setTimeout(() => {
              _this.done();
              window.flinked.reinit()
            }, 800);


            jQuery(this.oldContainer).hide();

            $el.css({
              visibility : 'visible',
              // opacity : 0
            });

            // $el.animate({ opacity: 1 }, 400, function() {

            //   _this.done();
            //   window.flinked.reinit()
            // });
          }
        });

        return caseTransition
  }

  autoScroll(dur, section) {
    // select DOM element
    const page = window;

    // init variable

    const to = section;
    const duration = dur;
    const start = page.scrollY;
    const change = to - start;
    let currentTime = 0;
    const increment = 20;

    // animate fonction

    const animateScroll = () => {
        currentTime += increment;
        const val = this.easeInOutQuad(currentTime, start, change, duration);
        page.scroll(0,val);
        if (currentTime < duration) {
        setTimeout(animateScroll, increment);
        }
    };
    animateScroll();
    }

    /**
     * @function easeInOutQuad
     * @description ease transition for closing scroll
     */
    easeInOutQuad(tP, bP, cP, dP) {
    let t = tP;
    const b = bP;
    const c = cP;
    const d = dP;

    t /= d / 2;
    if (t < 1) return c / 2 * t * t + b;
    t -= 1;
    return -c / 2 * (t *(t - 2) - 1) + b;
    }

    getCoords(elem) { // crossbrowser version
      var box = elem.getBoundingClientRect();

      var body = document.body;
      var docEl = document.documentElement;

      var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
      var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

      var clientTop = docEl.clientTop || body.clientTop || 0;
      var clientLeft = docEl.clientLeft || body.clientLeft || 0;

      var top  = box.top +  scrollTop - clientTop;
      var left = box.left + scrollLeft - clientLeft;

      return { top: Math.round(top), left: Math.round(left) };
  }

    init() {

    }
}

export default BarbaHelper
