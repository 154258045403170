class Blog
{
    constructor( options )
    {
        this.container = document.querySelector('.philosophy__intro__container__article')
        this.blogList = document.querySelector('.philosophy__intro__container__wrapper')
        this.blogItem = document.querySelectorAll('.philosophy__intro__container__wrapper .blogItem')
        this.blogButton = document.querySelector('.philosophy__intro__container__article__btn')
        this.size = 0
        this.fullSize = 0
        this.open = false
        this.currentSize = 0
        this.singleSize = 0
        this.numberOfEl = 3
        this.totalOfEl = 0
    }

    init() {
        if(document.querySelector('.philosophy__intro__container__wrapper')) {
            this.blogItem.forEach((element, key) => {
                if(key ===  0) {
                    this.singleSize = element.offsetHeight
                }
                if(key < 3) {
                    this.size += element.offsetHeight
                }
                this.totalOfEl += 1
                this.currentSize = this.size
                this.fullSize += element.offsetHeight
            });

            this.blogList.style.maxHeight = `${this.size}px`
            setTimeout(() => {
                this.blogList.style.transition = 'all ease 0.3s';
            }, 100);

            this.blogButton.addEventListener('click', (e) => {
                e.preventDefault();
                // if(!this.open || this.numberOfEl < this.totalOfEl) {
                if(this.numberOfEl < this.totalOfEl) {
                    this.open = true
                    // this.blogList.style.maxHeight = `${this.fullSize}px`
                    this.numberOfEl += 3
                    this.currentSize = this.singleSize * this.numberOfEl
                    this.blogList.style.maxHeight = `${this.currentSize}px`
                
                    if(this.numberOfEl >= this.totalOfEl) {
                        this.container.classList.add('show')
                        this.blogButton.innerHTML = "See less"
                    }
                } else {
                    this.numberOfEl = 3
                    this.currentSize = this.size
                    this.open = false
                    this.blogList.style.maxHeight = `${this.size}px`
                    this.container.classList.remove('show')
                    this.blogButton.innerHTML = "More Ideas"
                }
            })
        }
    }
}
export default Blog