import Hammer from 'hammerjs'

class Slider
{
    /**
     * Constructor
     */
    constructor( element )
    {
        this.container = element
        this.mover = element.querySelector('.customSlider__content__mover')
        this.allSlide = element.querySelectorAll('.customSlider__content__mover__item')
        this.nextBtn = element.querySelector('.customSlider__content__btnRight')
        this.prevBtn = element.querySelector('.customSlider__content__btnLeft')
        this.total = document.querySelector('.customSlider__total')
        this.current = document.querySelector('.customSlider__count')
        this.indexSlide = 0;
        this.count = 0;
        this.groupCount = 0;
        this.step = 0
        this.large = false
        this.classicIndex = 0
    }

    setCount(number) {
        if(number <= 10) {
            return `0${number}`
        }

        return number
    }

    theIndex(number) {
    
        if(number === this.groupCount) {
            return 1
        }
        if(number > this.groupCount) {
            return (number + 1) - this.groupCount
        }

        return number + 1
    }

    setSlider() {
        this.count = this.allSlide.length
        this.total.innerHTML = `/${this.setCount(this.count / 2)}`
        this.current.innerHTML = this.setCount(1)
        this.groupCount = this.count / 2
        this.step = this.allSlide[0] ? this.large ? this.allSlide[0].offsetWidth : this.allSlide[0].offsetWidth + 24 : 0
        this.prevBtn.style.opacity = "0"
    }

    nextEvent() {
        this.nextBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.nextCallBack()
            this.prevBtn.style.opacity = "1"
        })
    }

    prevEvent() {
        this.prevBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.prevCallback()
        })
    }

    nextCallBack() {
        this.indexSlide += 1
        this.step = this.large ? this.allSlide[this.indexSlide].offsetWidth : this.allSlide[this.indexSlide].offsetWidth + 24

        if(this.indexSlide === this.groupCount + 1) {
            this.classicIndex += 1
            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
            this.prevBtn.style.opacity = "0"
            setTimeout(() => {
                this.indexSlide = 1
                this.current.innerHTML = this.setCount(this.theIndex(this.indexSlide))
                this.mover.classList.add('noTrans')
                this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
                setTimeout(() => {
                    this.mover.classList.remove('noTrans')
                }, 20);
            }, 550);
            setTimeout(() => {
                this.mover.classList.remove('noTrans')
            }, 10);
        } else {
            this.classicIndex += 1
            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
            this.current.innerHTML = this.setCount(this.theIndex(this.indexSlide))
        }


    }

    prevCallback() {
        this.indexSlide -= 1
        if (this.indexSlide === 0) {
            this.prevBtn.style.opacity = "0"
        }
        if(this.indexSlide === -1) {
            this.indexSlide = 0
        
        } else {
            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
        }

        this.current.innerHTML = this.setCount(this.classicIndex + 1)
        this.current.innerHTML = this.setCount(this.theIndex(this.indexSlide))
    }


    swipeNavigation() {
        const options = {
            preventDefault: true,
        };
        const hammer = new Hammer(this.container, options);
        hammer.on('swipeleft swiperight', (e) => {
            if (e.type === 'swipeleft') {
                this.nextCallBack()
                this.prevBtn.style.opacity = "1"
            } else if (e.type === 'swiperight') {
                this.prevCallback()
            }
        });
    }

    init(element, type) {
        console.log('type', type)
        this.large = type
        if(element) {
            this.setSlider()
            this.nextEvent()
            this.prevEvent()
            this.swipeNavigation()
        }
    }
}

class AllCustomSlider
{
    constructor( options )
    {

    }

    init() {
        if(document.querySelector('.customSlider')) {
            const allSlider = document.querySelectorAll('.customSlider')
            allSlider.forEach(element => {
                const large = element.getAttribute('data-type')
                const slider = new Slider(element, large ? true : false)
                slider.init(element, large ? true : false)
            });
        }
    }
}
export default AllCustomSlider

