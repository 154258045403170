class Form
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.input = document.querySelectorAll('.contact__form input:not(.gform_hidden):not(.gform_button):not([type=hidden]), .contact__form textarea')
        this.inputObj = []
        this.currentParent = null
        this.indexSection = 1;
    }

    getNextParent() {
        const obj = this.inputObj.find((el) => {
            return el.parent !== this.currentParent && el.focus !== true
        });

        return obj
    }

    nextStep(array) {
        array.fill = true;
        array.focus = true;
        this.getNewSection(this.getNextParent())
    }

    watchForm() {
        this.inputObj.forEach(element => {
            let input = element.el
            let parent = element.parent
            input.addEventListener('input', (e) => {
                if(element.fill === false) {
                    this.nextStep(element)
                }
            })

        });
    }

    getNewSection(newParent) {
        let oldParent = this.currentParent
        this.currentParent = newParent.parent
        this.showSection()
        this.inputObj.forEach(element => {
            if(element.parent === this.currentParent && element.focus === false) {
                element.focus = true;
            } else if(element.parent === oldParent && element.fill === false) {
                element.fill = true;
                element.focus = true;
            }
        });
    }

    getFirst() {
        if(this.inputObj[0].parent !== undefined) {
            this.currentParent = this.inputObj[0].parent
            this.showSection()
            this.inputObj.forEach(element => {
                if(element.parent === this.currentParent) {
                    element.focus = true;
                }
            });
        }
    }

    showSection() {
        this.currentParent.classList.add('active')
    }


    getInput() {
        this.input.forEach(element => {
            let obj = {
                el: element,
                parent: this.getParent(element),
                fill: false,
                focus: false
            }

            this.inputObj.push(obj)
        });
    }

    getParent(el) {
        let parent = el
        if(el !== undefined && el !== null && el && parent instanceof Element) {
            console.log('parent ---', parent, 'end ---')
            if(parent.classList.contains('gfield_course_option_wrapper')) {
                return parent
            } else {
                parent = el.parentNode
                return this.getParent(parent)
            }
        }

    }

    init() {
        if(document.querySelector('.contact__form')) {
            // this.getInput()
            // this.getFirst()
            // this.watchForm()
        }
    }
}

export default Form
