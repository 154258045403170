import Coords from './Coord.js'
import AutoScroll from './AutoScroll'
import EasingFunctions from './Easing.js'
import {TweenMax, Power2, TimelineLite} from "gsap";

class RevealScroll
{
    /**
     * Constructor
     */
    constructor( options )
    {
      this.animateEl = document.querySelectorAll('.to-animate')
      this.body = document.querySelector('body')
      this.container = document.querySelector('.page--wrapper')
      this.menuEl = document.querySelector('.header__container')
      this.offset = 0;
      this.el = [];
      this.coords = new Coords;
      this.scrollCallBackBinded = this.scrollCallback.bind(this)
      this.autoScroll = new AutoScroll
      this.prevScroll = 0;
      this.hideMenu = false;
      this.colorMenu = false;
    }

    scrollEvent() {
        window.addEventListener('scroll', this.scrollCallBackBinded)
    }

    scrollCallback(e) {
        this.offset = window.scrollY;
        this.midwindoHeight = window.innerHeight / 2;

        this.menuReveal()

        this.el.forEach(element => {
            if(element.type === 'reveal') {
                if(element.offset < this.offset && element.animate === false) {
                    element.animate = true
                    element.el.classList.add('active-animation');
                  }
            } else if(element.type === "contact") {
                const contactsElement = document.querySelectorAll('.contactAnimateEl')

                if(contactsElement) {
                    contactsElement.forEach(singleEl => {
                        const coords = element.offset + singleEl.offsetTop - (singleEl.offsetWidth ) - window.innerHeight
                        if((coords) < this.offset) {
                            if(!singleEl.classList.contains('contactTransition')) {
                                singleEl.classList.add('contactTransition')
                            }
                        } else {
                            if(singleEl.classList.contains('contactTransition')) {
                                singleEl.classList.remove('contactTransition')
                            }
                        }
                    });
                }

                if(element.offset < this.offset && element.animate === false && !document.querySelector('.page404')) {
                    let interval = 1500
                    element.animate = true
                    element.prevScroll = this.offset;
                    setTimeout(() => {
                        element.el.classList.add('active-contact');
                        this.body.classList.add('body-contact');
                    }, 100);
                } else if(element.offset > this.offset && element.animate === true) {
                    element.animate = false
                    element.el.classList.remove('active-contact');
                    this.body.classList.remove('body-contact');
                    element.prevScroll = this.offset;
                }
            } else if(element.type === "parallax") {
                if(element.offset - (this.midwindoHeight / 2) < this.offset && element.offset + element.height + (this.midwindoHeight / 2) > this.offset) {
                    element.el.classList.add('active-animation');
                   this.parallaxEffect(element)
                   element.prevScroll = this.offset;
                }
            } else {
                if(element.offset < this.offset) {
                    element.el.classList.add('active-animation');
                }
            }
        });

        this.prevScroll = window.scrollY;
    }

    menuReveal() {
        let scrollValue = this.offset
        let prevScroll = this.prevScroll

        this.menuColor()

        if(scrollValue > 30) {
            if(this.hideMenu === false && prevScroll < scrollValue) {
                this.hideMenu = true
                this.menuEl.classList.add('hide')
            } else if(this.hideMenu === true && prevScroll > scrollValue) {
                this.hideMenu = false
                this.menuEl.classList.remove('hide')
            }
        }
    }

    menuColor() {
        if(document.querySelector('.singleWork__main')) {
            let el = document.querySelector('.singleWork__main')
            let color = el.getAttribute('data-color')

            if(this.offset < el.offsetHeight && this.colorMenu === false) {
                this.colorMenu = true
                if(color === 'white') {
                    this.body.classList.remove('body--header--page');
                    this.body.classList.remove('body--header--black');
                    this.body.classList.add('body--header--white');
                } else {
                    this.body.classList.remove('body--header--page');
                    this.body.classList.remove('body--header--white');
                    this.body.classList.add('body--header--black');
                }
            } else if(this.offset > el.offsetHeight && this.colorMenu === true) {
                this.colorMenu = false
                if(color === 'white') {
                    this.body.classList.remove('body--header--white');
                    this.body.classList.add('body--header--page');
                } else {
                    this.body.classList.remove('body--header--black');
                    this.body.classList.add('body--header--page');
                }
            }
        } else {
            this.body.classList.add('body--header--page');
            this.body.classList.remove('body--header--white');
            this.body.classList.remove('body--header--black');
        }
    }

    contactAnimation(parent) {
        let wrapper = document.querySelector('.footer__wrapper')
        let textPath = document.querySelector('.footer__clip')

        // let step = 1
        let sens = 'down'
        let sizeArea = parent.height
        let step =  50

        if(parent.prevScroll <= window.scrollY) {
            if(sens === 'top') {
                sens = 'down'
            }
        } else {
            if(sens === 'down') {
                sens = 'top'
            }
        }

        if(sens === 'down') {
            if(parent.data.scroll > 0 ) {
                parent.data.scroll -= step
                // wrapper.style.transform = `translateY(${parent.data.scroll}%)`
                // textPath.style.clipPath = `inset(${parent.data.scroll}% 0% 0% 0%)`
                TweenMax.to(wrapper, 1, {
                    y: parent.data.scroll,
                    ease: Power2.easeOut
                  })
            }
        } else if(sens === 'top') {

            if(parent.data.scroll < sizeArea) {
                parent.data.scroll += step
                // wrapper.style.transform = `translateY(${parent.data.scroll}%)`
                // textPath.style.clipPath = `inset(${parent.data.scroll}% 0% 0% 0%)`
                TweenMax.to(wrapper, 1, {
                    y: parent.data.scroll,
                    ease: Power2.easeOut
                  })
            }
        }
    }

    easeInOutQuad(tP, bP, cP, dP) {
        let t = tP;
        const b = bP;
        const c = cP;
        const d = dP;

        t /= d / 2;
        if (t < 1) return c / 2 * t * t + b;
        t -= 1;
        return -c / 2 * (t *(t - 2) - 1) + b;
    }

    followScroll(element ){
        const distX = element.indexScroll - element.x;

        element.x += distX/15;

        element.el.style.transform = `translateY(${element.x}px)`
    }

    parallaxEffect(parent) {

        let sens = 'down'
        let sizeArea = parent.height

        if(parent.prevScroll <= window.scrollY) {
            if(sens === 'top') {
                sens = 'down'
            }
        } else {
            if(sens === 'down') {
                sens = 'top'
            }
        }

        parent.data.child.forEach((element, i) => {
            let step =  ((element.limit / sizeArea) * 2)  * 10

            if(sens === 'down') {
                if(element.indexScroll >= (-Math.abs(element.limit) * 2)) {
                    element.indexScroll -= step
                    element.el.style.transform = `translateY(${element.indexScroll}px)`
                }
            } else if(sens === 'top') {
                if(element.indexScroll <= (Math.abs(element.limit) * 1)) {
                    element.indexScroll += step
                    element.el.style.transform = `translateY(${element.indexScroll}px)`
                }
            }

            // setInterval(this.followScroll(element), 10);

        });
    }

    setObj() {
      this.animateEl.forEach(element => {
        let offsetDelay
        let type = 'reveal'
        let dataToPush = {};

        if(element.getAttribute('data-type')) {
            type = element.getAttribute('data-type')
            if(type === "parallax") {
                let el = element.querySelectorAll('.to-animate-parallax')
                dataToPush.child = [];
                el.forEach(child => {
                    let style = child.currentStyle || window.getComputedStyle(child);
                    let limit = parseInt(style.marginTop.replace('px',''));
                    if(child.getAttribute('data-limiteOffset')) {
                        limit+=parseInt(child.getAttribute('data-limiteOffset'))
                    }
                    let data = {
                        el: child,
                        limit: limit,
                        indexScroll: 0,
                        x:0
                    }
                    dataToPush.child.push(data)
                });
            }
            if(type === "contact") {
                dataToPush.scroll = element.offsetHeight
                let content = element.querySelector('.container__full')
                let clone = document.querySelector('.footer__clip')
                clone.appendChild(content.cloneNode(true))
            }
        }

        if(element.getAttribute('data-offset')) {
            if(element.getAttribute('data-offset') === "mid") {
                if(element.getAttribute('data-offset2')) {
                    offsetDelay = window.innerHeight / 2 + parseInt(element.getAttribute('data-offset2'))
                } else {
                    offsetDelay = window.innerHeight / 2
                }
            } else if(element.getAttribute('data-offset') === "bottom") {
                if(element.getAttribute('data-offset2')) {
                    offsetDelay = window.innerHeight + parseInt(element.getAttribute('data-offset2'))
                } else {
                    offsetDelay = window.innerHeight
                }
            } else {
                offsetDelay = parseInt(element.getAttribute('data-offset'))
            }
        } else {
            offsetDelay = 0;
        }

        let obj = {
          el: element,
          offset: this.coords.getCoords(element).top - offsetDelay,
          height: element.offsetHeight,
          delay: offsetDelay,
          animate: false,
          type: type,
          prevScroll: 0,
          indexScroll: 0,
          data: dataToPush,
        }
        this.el.push(obj)
      });
    }

    initPage() {
        this.body.classList.remove('body-contact');
        this.el.forEach(element => {
            if(window.scrollY > element.offset && element.animate === false) {
                element.animate = true
                if(element.type != "contact") {
                    element.el.classList.add('active-animation');
                } else {
                    if(!document.querySelector('.page404')) {
                        element.animate = true
                        element.el.classList.add('active-contact');
                        this.body.classList.add('body-contact');
                    }
                }
            }
        });
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollCallBackBinded)
        this.el = []
      }

    init() {
      if(document.querySelector('.to-animate')) {
        this.menuColor()
        this.setObj();
        this.scrollEvent();
        this.initPage();
      }
    }
}

export default RevealScroll
