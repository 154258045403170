

class Loader
{
    /**
     * Constructor
     */
    constructor( options )
    {
      this.loaderEl = document.querySelector('.loader')
      this.loaderVideo = document.querySelector('.loader__video')
      this.loaderWrapperOne = document.querySelector('.loader__wrapperOne')
      this.loaderWrapperTwo = document.querySelector('.loader__wrapperTwo')
      this.loaderindication = document.querySelector('.loaderIndication')
      this.endLoading = false;
      this.endAnimation = false;
      this.media = {
        image: [],
        video: [],
      }
    }

    getBackground() {
      const elementNames = ["div", "body", "td"] // Put all the tags you want bg images for here
      const allBackgroundURLs = new Array();
      elementNames.forEach((tagName) => {
          const tags = document.getElementsByTagName(tagName);
          const numTags = tags.length;
          for (let i = 0; i < numTags; i++) {
              let tag = tags[i];
              if (tag.style.backgroundImage) {
                  let bg;
                  let bgTemp;
                  let pushUrl;

                  if(tag.style.backgroundImage.includes('"')) {
                    bg = tag.style.backgroundImage;
                    bgTemp = bg.replace('url("', "");
                    pushUrl = bgTemp.replace('")', "")
                  } else {
                    bg = tag.style.backgroundImage;
                    bgTemp = bg.replace('url(', "");
                    pushUrl = bgTemp.replace(')', "")
                  }
                  if(!this.media.image.includes(pushUrl)) {
                    this.media.image.push(pushUrl);
                  }
              }
          }
      })
    }

    getImageTag() {
      let allImage = document.querySelectorAll('img')

      if(document.querySelector('img')) {
        allImage.forEach(image => {
          let pushUrl = image.getAttribute('src')
          if(!this.media.image.includes(pushUrl)) {
            this.media.image.push(pushUrl);
          }
        });
      }
    }

    getVideoTag() {
      let allVideo = document.querySelectorAll('video')

      if(document.querySelector('video')) {
        allVideo.forEach(video => {
          let pushUrl = video.getAttribute('src')
          if(!this.media.video.includes(pushUrl)) {
            if(pushUrl !== null) {
              this.media.video.push(pushUrl);
            }
          }
        });
      }
    }

    scrapMedia() {
      this.getBackground()
      this.getImageTag()
    }

    initLoader() {
      const loader = new PxLoader()

      this.media.image.forEach(element => {

        var pxImage = new PxLoaderImage(element);

        loader.add(pxImage);
      });

      this.media.video.forEach(element => {

        var pxImage = new PxLoaderVideo(element);

        loader.add(pxImage);
      });

      loader.addProgressListener((e) => {

        let value = 100 / e.totalCount
        let total = e.totalCount
        let current = Math.round(e.completedCount * value)

        this.loaderWrapperOne.style.width = `${current}vw`
        this.loaderWrapperTwo.style.width = `${current}vw`
        this.loaderindication.innerHTML = current + '%'
        if(e.completedCount === e.totalCount) {
          this.endLoading = true;
          setTimeout(() => {
            this.loaderEl.classList.add('showVideo');
            this.loaderVideo.addEventListener('ended', (e) => {
              if(window.innerWidth > 750 || this.getCookie('videoLoader') !== "true") {
                this.endLoader()
              }
            });
            setTimeout(() => {
              if(window.innerWidth > 750 || this.getCookie('videoLoader') !== "true") {
                this.loaderVideo.play();
                this.loaderEl.classList.add('secondStep')
                this.loaderEl.addEventListener('click', (e) => {
                    this.endLoader()
                })
              }
            }, 600);

            if(window.innerWidth <= 750 || this.getCookie('videoLoader') === "true") {
              this.endLoader()
            }

          }, 1000);
        }

      });

      loader.start();
    }

    endLoader() {
        this.loaderEl.classList.add('end')
        this.setCookie('videoLoader','true',30);
        setTimeout(() => {
            if(document.querySelector('main')) {
                let page = document.querySelector('main')
                let body = document.querySelector('body')
                setTimeout(() => {
                  page.classList.add('activePage')
                  body.classList.add('activePageGlobal');
                }, 10);
              }
        }, 1000);
        setTimeout(() => {
            this.loaderEl.innerHTML = ""
        }, 1300);
    }

    setCookie(name,value,days) {
      let expires = "";
      if (days) {
          let date = new Date();
          date.setTime(date.getTime() + (days*24*60*60*1000));
          expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    }
    getCookie(name) {
        let nameEQ = name + "=";
        let ca = document.cookie.split(';');
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    eraseCookie(name) {   
        document.cookie = name+'=; Max-Age=-99999999;';  
    }


    init() {
        this.scrapMedia()
        this.initLoader()
    }
}

export default Loader
