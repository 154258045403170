import Rellax from 'Rellax'

class Parallax
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.element = document.querySelectorAll('.parallax')
        this.elementCol = document.querySelectorAll('.parallax_col')
    }

    init() {

        if(window.innerWidth > 750) {
            this.element.forEach(element => {
                let rellax = new Rellax(element, {
                    speed: -2,
                    center: true,
                });
            });
            this.elementCol.forEach(element => {
                let rellax = new Rellax(element, {
                    speed: -2,
                });
            });
        }
    }

    destroy() {

    }
}

export default Parallax
