class Slider404
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.current = 1;
        this.container = document.querySelector('.page404__slideshow__container')
        this.allSlide = document.querySelectorAll('.page404__slideshow__item')
    }

    initScript() {
        this.container.addEventListener('click', (e) => {
            e.preventDefault()
            let currentItem = document.querySelector(`.page404__slideshow__item--${this.current}`)

            this.current += 1;
            if(this.current > 3) {
                this.current = 1;
            }

            let newItem = document.querySelector(`.page404__slideshow__item--${this.current}`)

            currentItem.style.opacity = 0;
            setTimeout(() => {
                currentItem.style.opacity = 1; 
            }, 300);

            if(this.current === 1) {
                this.setNewSlide(0, 3, -14, -14)
                this.setNewSlide(1, 2, 0, 0)
                this.setNewSlide(2, 1, 14, 14)
            } else if(this.current === 2) {
                this.setNewSlide(0, 1, 14, 14)
                this.setNewSlide(1, 3, -14, -14)
                this.setNewSlide(2, 2, 0, 0)
            } else if(this.current === 3) {
                this.setNewSlide(0, 2, 0, 0)
                this.setNewSlide(1, 1, 14, 14)
                this.setNewSlide(2, 3, -14, -14)
            }
            console.log(document.querySelector(`.page404__slideshow__item--${this.current}`))
        })
    }

    setNewSlide(index, zindex, top, left) {
        this.allSlide[index].style.zIndex = zindex
        this.allSlide[index].style.top = top + 'px'
        this.allSlide[index].style.left = left + 'px'
        if(zindex === 3) {
            this.allSlide[index].style.backgroundImage = `url(${this.allSlide[index].getAttribute('data-src')})`
        } else {
            this.allSlide[index].style.backgroundImage = ""
        }
    }

    init() {
        if(document.querySelector('.page404')) {
            this.initScript()
            document.querySelector('body').classList.add('activePageGlobal')
        }
    }
}

export default Slider404
