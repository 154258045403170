class MobileMenu
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.body = document.querySelector('body')
        this.open = document.querySelector('.header__burger')
        this.close = document.querySelector('.header__close')
        this.openMenuBinded = this.openMenu.bind(this)
        this.closeMenuBinded = this.closeMenu.bind(this)
    }

    setMobileMenu() {
        this.open.addEventListener('click', this.openMenuBinded)
        this.close.addEventListener('click', this.closeMenuBinded)
    }

    openMenu() {
        console.log('hiiii')
        if(!this.body.classList.contains('openMobileMenu')) {
            this.body.classList.add('openMobileMenu')
        }
    }

    closeMenu() {
        if(this.body.classList.contains('openMobileMenu')) {
            this.body.classList.remove('openMobileMenu')
        }
    }

    destroy() {
        this.open.removeEventListener('click', this.openMenuBinded)
        this.close.removeEventListener('click', this.closeMenuBinded)
      }

    init() {
        // if(window.innerWidth < 750) {
        //     this.setMobileMenu()
        // }
        this.setMobileMenu()
    }
}

export default MobileMenu
