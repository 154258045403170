import Barba from 'barba.js'
import BarbaHelper from './BarbaHelper'

class PageTransition
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.helper = null
        this.transitionObj = {}
        this.lastClickEl = null
        this.body = document.querySelector('body')

    }

    BarbaConfig() {
        Barba.Pjax.start();
        Barba.Prefetch.init();

        // import help
        this.helper = new BarbaHelper;
        this.helper.settings();
        this.getBaseTransition();

        Barba.Dispatcher.on('linkClicked', (el) => {
            this.lastClickEl = el;
        });

        Barba.Pjax.getTransition = () => {
            let newPageName = Barba.Utils.getCurrentUrl().substr(Barba.Utils.getCurrentUrl().lastIndexOf("/") + 1)
            if(window.innerWidth > 750) {
                if(this.lastClickEl) {
                    if(this.lastClickEl.getAttribute('href').includes('contact')) {
                        this.body.classList.add('body-page-contact')
                    } else {
                        this.body.classList.remove('body-page-contact')
                    }
                    if(this.lastClickEl.getAttribute('class') === null || this.lastClickEl.getAttribute('class') === "basicTransition" || this.lastClickEl.classList.contains("basicTransition") || this.lastClickEl.getAttribute('class') === "header__logo") {
                        return this.helper.BasicTransition()
                    } else if(this.lastClickEl.classList.contains("caseStudyTransition")) {
                        return this.helper.caseTransition(this.lastClickEl)
                    } else {
                        return this.helper.BasicTransition()
                    }
                } else {
                    return this.helper.BasicTransition()
                }
            } else {
                if(this.lastClickEl) {
                    if(this.lastClickEl.getAttribute('href').includes('contact')) {
                        this.body.classList.add('body-page-contact')
                    } else {
                        this.body.classList.remove('body-page-contact')
                    }
                }
                return this.helper.BasicTransition()
            }
        };
    }

    getBaseTransition() {
        this.transitionObj.fade = this.helper.fadeTransition()
        // this.transitionObj.basic = this.helper.BasicTransition()
    }

    init() {
        this.BarbaConfig()
    }
}

export default PageTransition
