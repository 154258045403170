import Coords from './Coord.js'
class ValueScroll
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.number = document.querySelector('.philosophy__values__content__number')
        this.container = document.querySelector('.philosophy__values__content')
        this.mover = document.querySelector('.philosophy__values__content__numberMover')
        this.item = document.querySelectorAll('.philosophy__values__content__step__item')
        this.allItem = []
        this.scrollCallBackBinded = this.scrollCallBack.bind(this);
        this.coords = new Coords;
        this.offsetTopContainer = null;
        this.numberTopContainer = null;
        this.containerSize = null;
        this.numberSize = null;
        this.indexSection = 0;
        this.count;
        this.currentTransform = 0;
        this.itemSize;
    }

    setUp() {
        this.offsetTopContainer = this.coords.getCoords(this.container).top - 200;
        this.numberTopContainer = this.coords.getCoords(this.number).top;
        this.containerSize = this.container.offsetHeight;
        this.numberSize = this.number.offsetHeight;
        this.itemSize = this.item[0].offsetHeight;
        this.count = this.item.length -1;

        this.item.forEach(element => {
            let obj = {
                div: element,
                offset: this.coords.getCoords(element).top  - window.innerHeight / 2 + 125,
                height: element.offsetHeight
            }

            this.allItem.push(obj)
        });
    }

    scrollEvent() {
        window.addEventListener('scroll', this.scrollCallBackBinded)
    }

    scrollCallBack() {
        if(window.scrollY >= this.offsetTopContainer && window.scrollY <= this.offsetTopContainer + this.containerSize - this.numberSize) {
            if(!this.number.classList.contains('fixed')) {
                this.number.classList.add('fixed')
                this.number.style.top = `${ this.coords.getCoords(this.item[0]).top - window.scrollY}px`
                this.number.classList.remove('end')
            }
        } else if(window.scrollY >= this.offsetTopContainer + this.containerSize - this.numberSize) {
            if(!this.number.classList.contains('end')) {
                this.number.classList.remove('fixed')
                this.number.style.top = ''
                this.number.classList.add('end')
            }
        } else if(window.scrollY <= this.offsetTopContainer) {
            if(this.number.classList.contains('end')) {
                this.number.classList.remove('end')
            }
            if(this.number.classList.contains('fixed')) {
                this.number.classList.remove('fixed')
                this.number.style.top = ''
            }
        }

        if(window.scrollY >= this.offsetTopContainer && window.scrollY <= this.offsetTopContainer + this.containerSize - this.numberSize ) {
            this.allItem.forEach((element, i) => {
                let offset = element.offset
                let height = element.height

                let next;
                if(i === this.count) {
                    next = null
                } else {
                    next = i + 1
                }


                if(window.scrollY >= offset) {
                    console.log('start')
                    if(next !== null) {
                        if(window.scrollY <= this.allItem[next].offset) {
                            if(this.currentTransform !== i) {
                                this.currentTransform = i;
                                this.mover.style.transform = `translateY(-${this.numberSize * i}px)`
                            }
                        }
                    } else {
                        if(this.currentTransform !== i) {
                            this.currentTransform = i;
                            this.mover.style.transform = `translateY(-${this.numberSize * i}px)`
                        }
                    }
                }
            });
        }
    }

    init() {
        if(document.querySelector('.philosophy__values__content')) {
            this.setUp()
            this.scrollEvent()
        }
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollCallBackBinded)
        this.allItem = []
      }
}

export default ValueScroll
