class HoverLogo
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.header = document.querySelector('.header__container')
        this.logo = document.querySelector('.header__logo')
        this.video = document.querySelector('.videoFullScreen__container')
        this.videoEl = document.querySelector('.videoFullScreen')
        this.cursor = document.querySelector('.cursor')
        this.logoClip = document.querySelector('.header__logo__imgClip')
        this.showVideo = false;
        this.enterTimeout = null;
        this.enterTimeoutBis = null;
    }

    hoverEvent() {
        this.logo.addEventListener('mouseenter', (e) => {
            if(this.showVideo === false && !this.header.classList.contains('hide')) {
                this.showVideo = true
                this.logoClip.classList.add('active');
                this.logo.classList.add('active');
                this.enterTimeout = setTimeout(() => {
                    this.video.classList.add('active');
                    this.cursor.classList.add('loaderVideo');
                    this.enterTimeoutBis = setTimeout(() => {
                        // this.logoClip.classList.remove('active');
                        // this.logo.classList.remove('active');
                        this.videoEl.play()
                    }, 300);
                }, 2000);
                
            }
        })
        this.logo.addEventListener('mouseleave', (e) => {
            if(this.showVideo === true) {
                this.showVideo = false
                this.cursor.classList.remove('loaderVideo');
                this.video.classList.remove('active');
                this.logoClip.classList.remove('active');
                this.logo.classList.remove('active');
                clearTimeout(this.enterTimeoutBis)
                clearTimeout(this.enterTimeout)
                setTimeout(() => {
                    this.videoEl.pause()
                    this.videoEl.currentTime = 0;
                }, 300);
            }
        })

        this.logo.addEventListener('click', (e) => {
            clearTimeout(this.enterTimeoutBis)
            clearTimeout(this.enterTimeout)
            this.showVideo = false
            this.cursor.classList.remove('loaderVideo');
            this.video.classList.remove('active');
            this.logoClip.classList.remove('active');
            this.logo.classList.remove('active');
            this.videoEl.pause()
            this.videoEl.currentTime = 0;
        })
    }

    init() {
        if(window.innerWidth > 900) {
            this.hoverEvent()
        }
    }
}

export default HoverLogo
