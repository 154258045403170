class Gallery
{
    constructor( options )
    {
        this.galleryFull = document.querySelector('.slider__gallery__full')
        this.gallery = document.querySelector('.slider__gallery__mover')
        this.galleryProject = document.querySelector('.singleWork__slider--openGallery')
        this.galleryFullProject = document.querySelector('.singleWork__slider--galleryFull')
        this.close = document.querySelector('.slider__gallery__full__close')
        this.closeProject = document.querySelector('.singleWork__slider--closeGallery')
        this.body = document.querySelector('body')
    }

    openGallery() {
        this.body.classList.add('showGallery')
        this.galleryFull.classList.add('show')
        this.removeBodyScroll()
    }

    closeGallery() {
        this.body.classList.remove('showGallery')
        this.galleryFull.classList.remove('show')
        this.addBodyScroll()
    }

    init() {
        if(this.gallery) {
            this.gallery.addEventListener('click', (e) => {
                this.openGallery()
            })
            this.close.addEventListener('click', (e) => {
                e.preventDefault();
                this.closeGallery()
            })
        }
        if(this.galleryProject) {
            this.galleryProject.addEventListener('click', (e) => {
                e.preventDefault();
                this.body.classList.add('showGallery')
                this.galleryFullProject.classList.add('show')
                this.removeBodyScroll()
            })
            this.closeProject.addEventListener('click', (e) => {
                e.preventDefault();
                this.body.classList.remove('showGallery')
                this.galleryFullProject.classList.remove('show')
                this.addBodyScroll()
            })
        }
    }

    removeBodyScroll() {
        const body = document.querySelector('body');
        body.classList.add('lockedScroll');
        const { scrollY } = window;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}px`;
    };

    addBodyScroll() {
        const body = document.querySelector('body');
        body.classList.remove('lockedScroll');
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
    };
}
export default Gallery