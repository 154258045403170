import Coords from './Coord.js'
import AutoScroll from './AutoScroll'

class ProjectAnchor
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.section = document.querySelector('.singleWork__slider .container__content')
        this.btn = document.querySelector('.singleWork__main__container__content__btn')
        this.homebtn = document.querySelector('.home__main__container__content__btn__inlineBtn--mobile')
        this.whatWeDobtn = document.querySelector('.services__intro__container__scrollIndicator__content')
        this.whatWeDoSection = document.querySelector('.services__list__container')
        this.homeworkSection = document.querySelector('.home__works__container')
        this.coords = new Coords;
        this.autoScroll = new AutoScroll
    }

    clickEvent() {
        this.btn.addEventListener('click', (e) => {
            console.log('click event')
            e.preventDefault()
            if(this.section) {
                this.autoScroll.autoScroll(600, (this.coords.getCoords(this.section).top - 50));
            }
        })
    }

    clickHomeEvent() {
        this.homebtn.addEventListener('click', (e) => {
            e.preventDefault()
            if(this.homeworkSection) {
                this.autoScroll.autoScroll(600, (this.coords.getCoords(this.homeworkSection).top- 50));
            }
        })
    }

    clickWhatWeDo() {
        this.whatWeDobtn.addEventListener('click', (e) => {
            e.preventDefault()
            if(this.whatWeDoSection) {
                this.autoScroll.autoScroll(600, (this.coords.getCoords(this.whatWeDoSection).top- 50));
            }
        })
    }

    init() {
        if(document.querySelector('.singleWork__main__container__content__btn')) {
            this.clickEvent()
        }
        if(document.querySelector('.home__main__container__content__btn__inlineBtn--mobile')) {
            this.clickHomeEvent()
        }
        if(document.querySelector('.services__list__container') && document.querySelector('.services__intro__container__scrollIndicator__content')) {
            this.clickWhatWeDo()
        }
    }
}

export default ProjectAnchor
