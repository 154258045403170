import Coords from './Coord.js'

class ScrollSection
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.allSection = document.querySelectorAll('.scrollingSection')
        this.sectionContainer = document.querySelector('.header__sectionIndicator')
        this.sectionMover = document.querySelector('.header__sectionIndicator__mover')
        this.sectionItem;
        this.section = []
        this.coords = new Coords;
        this.indexSection = 0;
        this.sectionCount;
        this.itemSize;
        this.currentTransform = 0;
        this.scrollCallBackBinded = this.scrollCallBack.bind(this)
    }

    createSectionItem(text) {
        let div = document.createElement('div')
        div.classList.add('header__sectionIndicator__item')
        div.innerText = text

        this.sectionMover.appendChild(div)
    }

    getSection() {

        this.allSection.forEach(element => {
            let obj = {
                div: element,
                offset: this.coords.getCoords(element).top - window.innerHeight / 2,
                text: element.getAttribute('data-section'),
                show: false,
            }
            this.createSectionItem(obj.text)
            this.section.push(obj)
            this.sectionItem = document.querySelector('.header__sectionIndicator__item')
            this.size = this.sectionItem.offsetHeight;
        });
    }

    scrollEvent() {
        window.addEventListener('scroll', this.scrollCallBackBinded)
        if(window.scrollY >= this.section[0].offset) {
            this.sectionMover.style.transform = `translateY(0px)`
        }
    }

    scrollCallBack() {
        this.section.forEach((element, i) => {

            let next;
            if(i === this.count) {
                next = null
            } else {
                next = i + 1
            }
            if(window.scrollY >= element.offset && element.show === false) {
                if(next !== null) {
                    if(window.scrollY <= this.section[next].offset) {
                        if(this.currentTransform !== i) {
                            this.currentTransform = i;
                            this.sectionMover.style.transform = `translateY(-${this.size * i}px)`
                        }
                    }
                } else {
                    if(this.currentTransform !== i) {
                        this.currentTransform = i;
                        this.sectionMover.style.transform = `translateY(-${this.size * i}px)`
                    }
                }
            }
        });
    }

    init() {
        if(document.querySelector('.scrollingSection')) {

            this.count = this.allSection.length -1;

            this.getSection()
            this.scrollEvent()
        }
    }

    destroy() {
        window.removeEventListener('scroll', this.scrollCallBackBinded)
        this.sectionMover.innerHTML = ""
        this.section = []
      }
}

export default ScrollSection
