import Hammer from 'hammerjs'

class ProjectSlider
{
    /**
     * Constructor
     */
    constructor( element )
    {
        this.container = element.querySelector('.singleWork__slider__content')
        this.mover = element.querySelector('.singleWork__slider__content__mover')
        this.allSlide = element.querySelectorAll('.singleWork__slider__content__mover__item')
        this.nextBtn = element.querySelector('.singleWork__slider__content__btnRight')
        this.prevBtn = element.querySelector('.singleWork__slider__content__btnLeft')
        this.indexSlide = 0;
        this.count = 0;
        this.groupCount = 0;
        this.step = 0
        this.large = false
    }

    setSlider() {
        if(this.allSlide && this.allSlide[0]) {
            this.count = this.allSlide.length
            this.groupCount = this.count / 2
            this.step = this.large ? this.allSlide[0].offsetWidth : this.allSlide[0].offsetWidth + 24
            this.prevBtn.style.opacity = "0"
        }
    }

    nextEvent() {
        this.nextBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.nextCallBack()
            this.prevBtn.style.opacity = "1"
        })
    }

    prevEvent() {
        this.prevBtn.addEventListener('click', (e) => {
            e.preventDefault()
            this.prevCallback()
        })
    }

    nextCallBack() {
        this.indexSlide += 1

        if(this.indexSlide === this.groupCount) {
            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
            this.prevBtn.style.opacity = "0"
            setTimeout(() => {
                this.indexSlide = 0
                this.mover.classList.add('noTrans')
                this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
                setTimeout(() => {
                    this.mover.classList.remove('noTrans')
                }, 20);
            }, 500);
            setTimeout(() => {
                this.mover.classList.remove('noTrans')
            }, 10);
        } else {

            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
        }
    }

    prevCallback() {
        this.indexSlide -= 1

        if (this.indexSlide === 0) {
            this.prevBtn.style.opacity = "0"
        }
        if(this.indexSlide === -1) {
            this.indexSlide = 0
        } else {
            this.mover.style.transform = `translateX(-${this.step * this.indexSlide}px)`
        }
    }


    swipeNavigation() {
        const options = {
            preventDefault: true,
        };
        const hammer = new Hammer(this.container, options);
        hammer.on('swipeleft swiperight', (e) => {
            if (e.type === 'swipeleft') {
                this.nextCallBack()
                this.prevBtn.style.opacity = "1"
            } else if (e.type === 'swiperight') {
                this.prevCallback()
            }
        });
    }

    init(element, type) {
        this.large = type
        if(element) {
            this.setSlider()
            this.nextEvent()
            this.prevEvent()
            this.swipeNavigation()
        }
    }
}

class AllSlider
{
    constructor( options )
    {

    }

    init() {
        if(document.querySelector('.singleWork__slider')) {
            const allSlider = document.querySelectorAll('.singleWork__slider')
            allSlider.forEach(element => {
                const large = element.getAttribute('data-type')
                const slider = new ProjectSlider(element, large ? true : false)
                slider.init(element)
            });
        }
    }
}
export default AllSlider

