import Hammer from 'hammerjs'

class SingleSlider
{
    /**
     * Constructor
     */
    constructor( container, mover, item, btnLeft, btnRight, mobilMargin )
    {
        this.container = container;
        this.mover = mover;
        this.item = item;
        this.btnLeft = btnLeft;
        this.btnRight = btnRight;
        this.mobilMargin = mobilMargin
        this.count = 0;
        this.index = 0;
        this.offset = 0
    }

    clickEvent() {
        this.btnRight.addEventListener('click', (e) => {
            e.preventDefault();
            this.nextCallBack()
        })
        this.btnLeft.addEventListener('click', (e) => {
            e.preventDefault();
            this.backCallBack()
        })
    }

    nextCallBack() {
        if(this.index < this.count) {
            this.index += 1
        } else {
            this.index = 0;
        }

        this.mover.style.transform = `translate3d(-${this.offset * this.index}px, 0, 0)` 
    }

    backCallBack() {
        if(this.index > 0) {
            this.index -= 1
        } else {
            this.index = this.count;
        }

        this.mover.style.transform = `translate3d(-${this.offset * this.index}px, 0, 0)`
    }

    swipeNavigation() {
        const options = {
            preventDefault: true,
        };
        const hammer = new Hammer(this.container, options);
        hammer.on('swipeleft swiperight', (e) => {
            if (e.type === 'swipeleft') {
                this.nextCallBack()
            } else if (e.type === 'swiperight') {
                this.backCallBack()
            }
        });
    }

    init() {
        this.offset = this.item[0].offsetWidth + this.mobilMargin;
        this.count = this.item.length -1;
        this.clickEvent()
        this.swipeNavigation()
    }
}

class MobileSlider
{
    /**
     * Constructor
     */
    constructor( options )
    {

    }

    init() {
        if(window.innerWidth <= 750) {
            if(document.querySelector('.mobileSlider')) {
                let allSlider = document.querySelectorAll('.mobileSlider');
    
                allSlider.forEach(element => {
                    let container = element
                    let mover = element.querySelector('.mobileSlider__mover')
                    let item = element.querySelectorAll('.mobileSlider__item')
                    let btnLeft = element.querySelector('.mobileSlider__btnLeft')
                    let btnRight = element.querySelector('.mobileSlider__btnRight')
                    let mobilMargin = parseInt(element.getAttribute('data-mobileMargin'))
                    let singleSlider = new SingleSlider(container, mover, item, btnLeft, btnRight, mobilMargin)
                    singleSlider.init()
                });
            }
        }
    }
}

export default MobileSlider
