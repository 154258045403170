class CustomCursor
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.allLink = document.querySelectorAll('a, input, .ginput_container_radio label, textarea')
        this.allMore = document.querySelectorAll('.moreCursor')
        this.cursor = document.querySelector('.cursor')
        this.canMove =  true
        this.mouse =  { x: 0.5, y: 1 }
        this.cursorPos =  {x: 0, y: 0}
        this.activeTrailer =  false
        this.isHover =  false
        this.interval = null;
        this.firstTime = true
    }

    mouseEvent() {
        window.addEventListener('mousemove', (event) => {
            if(this.firstTime === true) {
              this.firstTime = false;
              this.cursor.classList.remove('hide')
            }
            if (this.canMove === true) {
                this.mouse.x = event.clientX;
                this.mouse.y = event.clientY;
            }
        });
    }

    followMouse(){
        const distX = this.mouse.x - this.cursorPos.x - (this.cursor.offsetHeight / 2);
        const distY = this.mouse.y - this.cursorPos.y -(this.cursor.offsetWidth / 2);

        this.cursorPos.x += distX/5;
        this.cursorPos.y += distY/2;

        this.cursor.style.transform = `translate3d(${this.cursorPos.x}px, ${this.cursorPos.y}px, 0)`;
    }

    headerLink() {    
        this.allLink.forEach(element => {
          element.addEventListener('mouseenter', (e) => {
            if(this.isHover === false) {
              this.isHover = true
              this.cursor.classList.add('linkHover');
            }
          })
          element.addEventListener('mouseleave', (e) => {
            if(this.isHover === true) {
              this.isHover = false
              this.cursor.classList.remove('linkHover');
              this.canMove = true;
            }
          })
        });

        this.allMore.forEach(element => {
          element.addEventListener('mouseenter', (e) => {
            if(this.isHover === false) {
              this.isHover = true
              this.cursor.classList.add('more');
            }
          })
          element.addEventListener('mouseleave', (e) => {
            if(this.isHover === true) {
              this.isHover = false
              this.cursor.classList.remove('more');
              this.canMove = true;
            }
          })
        });
    }

    init() {
        this.mouseEvent()
        this.headerLink()
        this.interval = setInterval(this.followMouse.bind(this), 30);
    }

    destroy() {
        // window.removeEventListener('scroll', this.scrollCallBackBinded)
        clearInterval(this.interval)
        this.interval = null
        this.allLink = []
        this.firstTime = true;
      }
}

export default CustomCursor
