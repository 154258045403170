function fallbackCopyTextToClipboard(text, context) {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.className = 'hidden';
    context.appendChild(textArea);
    textArea.focus();
    if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
      let oldContentEditable = textArea.contentEditable,
          oldReadOnly = textArea.readOnly,
          range = document.createRange();
  
      textArea.contenteditable = true;
      textArea.readonly = false;
      range.selectNodeContents(textArea);
      let s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);
      textArea.setSelectionRange(0, 999999); 
      textArea.contentEditable = oldContentEditable;
      textArea.readOnly = oldReadOnly;
  
    }
    else {
      textArea.select();
    }
    
    try {
      const successful = document.execCommand('copy');
      // console.log(successful);
      const msg = successful ? 'successful' : 'unsuccessful';
      
      successCopy();
    } catch (err) {
      console.error(err);
    }
  
    context.removeChild(textArea);
  }

  function successCopy() {
    alert('Le lien vers cet article a bien été copié dans le presse-papier.');
  }
  
 function copyTextToClipboard(text, context) {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text, context);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      successCopy();
    });
  }

  class CopyButton
  {
      constructor( options )
      {
        this.copyButton = document.querySelectorAll('.copyButton')
        this.copyEventBind = this.copyEvent.bind(this)
      }

      copyEvent(e) {
        e.preventDefault();
        let el = e.currentTarget
        copyTextToClipboard(el.getAttribute('data-copyLink'), e.currentTarget);
      }

      addEventToButton(button) {
        if(button) {
            button.addEventListener("click", this.copyEventBind, true);
        }
     }

      removeEventToButton(button) {
        if(button) {
            button.removeEventListener("click", this.copyEventBind, true);
        }
     }

    buttonEvent() {
        this.copyButton.forEach(element => {
            this.addEventToButton(element)
        });
    }


    destroy() {
        if(document.querySelector('.copyButton')) {
            this.copyButton.forEach(element => {
                this.removeEventToButton(element)
            });
        }
    }
  
    init() {
        if(document.querySelector('.copyButton')) {
            this.buttonEvent()
        }
    }
  }
  export default CopyButton