import AutoScroll from "./AutoScroll";

class BackToTop
{
    /**
     * Constructor
     */
    constructor( options )
    {
        this.autoScroll = new AutoScroll
        this.footerTopBtn = document.querySelector('.header__topBtn')
    }

    goToTopFooter() {
        this.footerTopBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.autoScroll.autoScroll(500, 0)
        })
    }

    init() {
        if(document.querySelector('.header__topBtn')) {
            this.goToTopFooter()
        }
    }
}

export default BackToTop
