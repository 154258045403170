import PageTransition from 'public/PageTransition'
import ScrollSection from 'public/ScrollSection'
import ValueScroll from 'public/ValueScroll'
import RevealScroll from 'public/RevealScroll'
import BackToTop from './public/BackToTop';
import ProjectSlider from './public/ProjectSlider';
import Form from './public/Form';
import InitPage from './public/InitPage';
import MobileMenu from './public/MobileMenu';
import CustomCursor from './public/CustomCusor';
import Parallax from './public/Parallax';
import MobileSlider from './public/MobileSlider';
import HoverLogo from './public/HoverLogo';
import ResizeEvent from './public/ResizeEvent';
import Loader from './public/Loader';
import ProjectAnchor from './public/ProjectAnchor';
import Slider404 from './public/Slider404';
import AllCustomSlider from './public/Slider';
import Gallery from './public/Gallery';
import Blog from './public/blog';
import Clipboard from './public/clipboard'

window.flinked = {}

function init() {

  if(!document.querySelector('main.contact') && !document.querySelector('.confirmation__contact')) {
    this.loader = new Loader
    this.loader.init()
  } else {
    let loader = document.querySelector('.loader')
    loader.style.display = "none";
    let page = document.querySelector('main')
    let body = document.querySelector('body')
    body.classList.add('body-page-contact')
    setTimeout(() => {
      page.classList.add('activePage')
      body.classList.add('activePageGlobal')
    }, 10);
  }

  if(document.querySelector('.page404')) {
    let footer = document.querySelector('.footer')
    footer.style.display = "none"
  }

  this.hoverLogo = new HoverLogo
  this.hoverLogo.init()
  
  window.flinked.reinit()
}

window.flinked.reinit = function() {
  window.flinked.destroy()
    /**
   * look slider
   */

  this.resizeEvent = new ResizeEvent();
  //  this.resizeEvent.init();

  this.pageTransition = new PageTransition();
  this.pageTransition.init();

  this.initPage = new InitPage();
  this.initPage.init();

  this.scrollSection = new ScrollSection();
  this.scrollSection.init();

  this.ValueScroll = new ValueScroll();
  this.ValueScroll.init();

  this.revealScroll = new RevealScroll();
  this.revealScroll.init();

  this.backtoTop = new BackToTop();
  this.backtoTop.init();

  this.projectSlider = new ProjectSlider();
  this.projectSlider.init();

  this.customSlider = new AllCustomSlider();
  this.customSlider.init();

  this.form = new Form();
  this.form.init();

  this.mobileMenu = new MobileMenu
  this.mobileMenu.init();

  this.customCursor = new CustomCursor
  this.customCursor.init();

  this.parallax = new Parallax
  this.parallax.init()

  this.mobileSlider = new MobileSlider
  this.mobileSlider.init()

  this.projectAnchor = new ProjectAnchor
  this.projectAnchor.init()

  this.slider404 = new Slider404
  this.slider404.init()

  this.gallery = new Gallery
  this.gallery.init()

  this.blog = new Blog
  this.blog.init()

      
    /* ---------------------------------- COPY ---------------------------------- */

    this.clipboard = new Clipboard
    this.clipboard.init()
  
}

window.flinked.destroy = function() {
  window.flinked.onDestroy(window.flinked.scrollSection);
  window.flinked.onDestroy(window.flinked.ValueScroll);
  window.flinked.onDestroy(window.flinked.mobileMenu);
  window.flinked.onDestroy(window.flinked.customCursor);
  window.flinked.onDestroy(window.flinked.parallax);
}

window.flinked.onDestroy = function(obj) {
    if(obj)
    {
        if(obj.destroy)
        {
            obj.destroy();
        }
        obj = null;
    }
}


window.onload = init;