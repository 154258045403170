class InitPage
{
    /**
     * Constructor
     */
    constructor( options )
    {

    }

    init() {

    }
}

export default InitPage
